import { Controller } from '@hotwired/stimulus'; 

export default class extends Controller { 

    static values = { captcha: String } 
    static targets = ['submit']; 
    
    connect () { 
        const sitekey = "6LexIYIeAAAAAIlp50D2c1fd7czbPrQmOB2qTJ8e"; 
       
        this.submitTarget.disabled = true; 
        
        var script = document.createElement('script'); 
        script.src = 'https://www.google.com/recaptcha/api.js'; 
        script.async = true; 

        script.onload = () => { 
            grecaptcha.ready(() => { 
                grecaptcha.render(this.captchaValue, { 
                    sitekey: sitekey, 
                    callback: this.verifyCallback.bind(this), 
                    'expired-callback': this.expiredCallback.bind(this) 
                }); 
            }); 
        }; 

        script.onerror = () => console.log('Error al cargar el script'); 

        document.querySelector("#javascripts").appendChild(script); 
    } 

    verifyCallback (response) { 
        this.submitTarget.disabled = false; 
    } 

    expiredCallback (response) { 
        this.submitTarget.disabled = true; 
    } 
}
