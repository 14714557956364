import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = { prototype: String, count: Number, nextIndex: Number, limit: {type: Number, default: null} };
    static targets = ['container', 'btnAdd'];

    /**
     * Método que se ejecuta cuando se hace clic en un elemento para agregar un nuevo widget.
     * 
     * @param {Event} event - El objeto de evento que se produjo.
     */
    add (event) {
        event.preventDefault();

        if (this.limitValue == null || this.countValue < this.limitValue) {
            // Crea un nuevo widget utilizando el valor de "prototype" y "count".
            const newWidget = this.prototypeValue.replace(/__name__/g, this.nextIndexValue);

            // Inserta el nuevo widget en el elemento "container".
            this.containerTarget.insertAdjacentHTML('beforeend', newWidget);

        // Incrementa el valor de "count" para el siguiente widget.
            this.nextIndexValue++;
            this.countValue++;

            if(this.limitValue != null && this.countValue >= this.limitValue) {
                this.btnAddTarget.disabled = true; 
            }
        } 
    }

    /**
     * Método que se ejecuta cuando se hace clic en un elemento para eliminar un widget existente.
     * 
     * @param {Event} event - El objeto de evento que se produjo.
     */
    remove (event) {
        event.preventDefault();

        // Obtiene el id del elemento objetivo, del atributo "data-element-target" del elemento que se hizo clic.
        const id = event.params.target;

        // Busca el elemento correspondiente y lo elimina de la página.
        const element = this.containerTarget.querySelector(id); 
        if (element) {
            element.remove();
           
            if(this.limitValue != null && this.countValue >= this.limitValue) {
                this.btnAddTarget.disabled = false; 
            }

            this.countValue--;
        }
    }
}