import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    // static values = { urls: Array };
    static targets = ['select'];

    /**
     * Método que se ejecuta cuando se cambia el valor de un campo de selección.
     */
    change(event) {
        // Indice del target 
        const index = parseInt(event.params.index); 
        const url = event.params.url; 
        const value = event.target.value;
        const nextIndex = index + 1;
        
        if (nextIndex < this.selectTargets.length) {
            // Si hay más selecciones, limpiar los valores de las selecciones siguientes.
            for (let i = nextIndex; i < this.selectTargets.length; i++) {
                this.selectTargets[i].innerHTML = '<option value="">--seleccione--</option>';
            }           
        }

        if (value) {
            // Si hay un valor seleccionado, cargar las opciones para la siguiente selección.
            this.load(url.replace('__id__', value))
                .then(json => {
                    let options = `<option  value=""> -- Seleccione -- </option>`;
                    json.forEach(item  => {
                        options += `<option value="${item.id}">${item.descripcion} </optin>`;
                    });
                    this.selectTargets[nextIndex].innerHTML = options;
                    // Llamar recursivamente al método changeSelection para la siguiente selección.
                  //  this.changeSelection(nextIndex);
                })
            ;
        }
    }

    /**
     * Método que carga las opciones de un campo de selección a través de una solicitud fetch.
     * 
     * @param {string} url - La URL de la API para cargar las opciones.
     * @returns {Promise<string>} - Una promesa que resuelve en una cadena de HTML que contiene las opciones.
     */
    load(url) {
        return fetch(url, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            }
        })
            .then(response => {
                if (!response.ok) throw new Error(`${response.status} - ${response.statusText}`);
                return response.json();
            })
            .then(json => json)
            .catch(error => {
                // Si hay un error, mostrar un mensaje en el último objetivo.
                const lastTarget = this.selectTargets[this.selectTargets.length - 1];
                lastTarget.innerHTML = `
                    <p class="text-center">
                        Error al recuperar el contenido <br />
                        Error-HTTP: ${error.message}
                    </p>
                `;
            })
        ;
    }
}
